import React from "react"
import SocialIcons from '../common/SocialIcons'
import { GatsbyImage } from "gatsby-plugin-image";

import '../../styles/components/hero-two-photos-text-right.scss'

class HeroTwoPhotosTextRight extends React.Component {
  render() {
    return (
      <section className="hero-two-photos-text-right dec-section--box-top">
        <div className="container">
          <div className="row">
            <div className="col text-align-right">
              <SocialIcons type={"light"} position={"relative"} />
            </div>
          </div>
          </div>
          <div className="container medium">
          <div className="row">
            <div className="col-md-3">
              <p dangerouslySetInnerHTML={{ __html: this.props.heroText }} className="h2 text-align-right" />
            </div>
            <div className="col-md-8 offset-md-1">
              <div className="hero__image-first dec-element--line-vertical">
                <GatsbyImage image={this.props.heroImage} loading="auto" alt="" />
              </div>
              <div className="hero__image-second">
                <GatsbyImage image={this.props.heroImage2} alt="" />
              </div>
              <h1 dangerouslySetInnerHTML={{ __html: this.props.heroHeader }} className="text-align-right" />
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default HeroTwoPhotosTextRight