import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { GatsbyImage } from "gatsby-plugin-image";

import { Layout } from '../components/common'
import { MetaData } from '../components/common/meta'
import ContactForm from '../components/sections/contact-form'
import HeroTwoPhotosTextRight from '../components/sections/hero-two-photos-text-right'

import Slider from 'react-slick'

import '../styles/pages/page-belowtheline.scss'
import { belowtheline as servicesSchema } from '../utils/schema/services'

const pageBelowTheLine = ({ location }) => {
let sliderBelow
let settings = {
  dots: false,
  arrows:false,
  slidesToShow: 3,
  lazyload: "ondemand",
  infinite: false,
  className: "below-line__slider",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2
      }
    },
    {
      breakpoint: 768,
      settings: {
        slidesToShow: 1
      }
    }
  ]
};

let previous = () => {sliderBelow.slickPrev()}
let next = () => {sliderBelow.slickNext()}

const data = useStaticQuery(graphql`{
  ghostPage: ghostPage(tags: {elemMatch: {name: {eq: "#page-belowtheline"}}}) {
    ...GhostPageFields
  }
  hero: file(relativePath: {eq: "hero/hero-belowtheline.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 770, layout: CONSTRAINED)
    }
  }
  hero2: file(relativePath: {eq: "hero/hero-belowtheline2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 520, layout: CONSTRAINED)
    }
  }
  photoSquare1: file(relativePath: {eq: "belowtheline/photoSquare1.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
  photoSquare2: file(relativePath: {eq: "belowtheline/photoSquare2.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
  photoSquare3: file(relativePath: {eq: "belowtheline/photoSquare3.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
  photoSquare4: file(relativePath: {eq: "belowtheline/photoSquare4.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
  photoSquare5: file(relativePath: {eq: "belowtheline/photoSquare5.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
  photoSquare6: file(relativePath: {eq: "belowtheline/photoSquare6.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
  photoSquare7: file(relativePath: {eq: "belowtheline/photoSquare7.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
  photoSquare8: file(relativePath: {eq: "belowtheline/photoSquare8.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
  photoSquare9: file(relativePath: {eq: "belowtheline/photoSquare9.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
  photoSquare10: file(relativePath: {eq: "belowtheline/photoSquare10.jpg"}) {
    childImageSharp {
      gatsbyImageData(width: 505, layout: CONSTRAINED)
    }
  }
}
`)
	const page = data.ghostPage

	return <>
        <MetaData
            data={data}
            location={location}
            type="website"
        />
        <Helmet>
            <style type="text/css">{`${page.codeinjection_styles}`}</style>
    <script type="application/ld+json">{JSON.stringify(servicesSchema)}</script>
        </Helmet>
        <Layout>
    <article className="page-below-the-line">
      <HeroTwoPhotosTextRight
        heroImage = {data.hero.childImageSharp.gatsbyImageData}
        heroImage2 = {data.hero2.childImageSharp.gatsbyImageData}
        heroHeader = "Below the line"
        heroText = "Connecting<br/> Online<br/> Behaviors<br/> with<br/> Offline<br/> Transactions"
      />
      <div className="below-line__content background__theme--dark">
        <div className="container medium">
          <section className="below-line__section text-align-right">
            <header className="d-flex">
              <span className="dec-element--line-horizontal" aria-hidden="true"></span>
              <h2 className="h1">Events &amp; Happenings</h2>
            </header>
            <p>Δημιουργούμε πρωτότυπα και καλαίσθητα events &amp; happenings, με στόχο την άμεση προβολή της εταιρείας ή των προϊόντων σας. Υπαίθρια party, διοργάνωση εγκαινίων, promotions, συναυλίες με τη Think plus θα δημιουργήσουν μία ξεχωριστή ατμόσφαιρα γύρω από την εκδήλωσή σας, ώστε αυτή να γίνει viral!</p>
            <p className="h5">Επιλέξτε την Think plus για το επερχόμενο event σας και δώστε νέα ώθηση στην εταιρική σας παρουσία.</p>
          </section>
          <section className="below-line__section text-align-left">
            <header className="d-flex">
              <h2 className="h1">Εκθεσιακά περίπτερα</h2>
              <span className="dec-element--line-horizontal" aria-hidden="true"></span>
            </header>
            <p>Στήστε το δικό σας "σκηνικό" με την Think plus στο επόμενο event και απογειώστε την εικόνα της επιχείρησής σας. Με γνώμονα το σύγχρονο design και τις νέες τάσεις στις διεθνείς εκθέσεις, δημιουργούμε το δικό σας εκθεσιακό περίπτερο προσαρμοσμένο επακριβώς στις δικές σας ανάγκες και απαιτήσεις. Διαθέτουμε πολυετή εμπειρία, άρτια εξειδικευμένο προσωπικό και εγγυόμαστε την αποτελεσματική προβολή της εταιρείας και των προϊόντων σας.</p>
            <p className="h5">Διαθέτουμε πολυετή εμπειρία και άρτια εξειδικευμένο προσωπικό!</p>
          </section>
          <section className="below-line__section text-align-right">
            <header className="d-flex">
              <span className="dec-element--line-horizontal" aria-hidden="true"></span>
              <h2 className="h1">Διαφημιστικά δώρα</h2>
            </header>
            <p>Τα διαφημιστικά δώρα αποτελούν σημαντικό εργαλείο Marketing για διαφημιστικές εκστρατείες, δώρα διαγωνισμών και happenings. Εμείς στη Think plus αναζητούμε την τελευταία τάση για επαγγελματικά δώρα και gadgets που θα δώσουν νέα πνοή στην προώθησή της εταιρείας σας. Αναλαμβάνουμε τον σχεδιασμό και την παραγωγή των διαφημιστικών δώρων που εσείς επιλέγετε, μέσα από την μεγάλη γκάμα προϊόντων που διαθέτουμε. Σε μας θα βρείτε giveaways, gadgets, δώρα συνεδρίων, ρουχισμό, τσάντες, δώρα τεχνολογίας κ.α. Με γνώμονα τις μοναδικές σας ανάγκες, εγγυόμαστε άριστη ποιότητα, άμεσο χρόνο παράδοσης και τις πιο ανταγωνιστικές τιμές της αγοράς!</p>
            <p className="h5">Σε μας θα βρείτε giveaways, gadgets, δώρα συνεδρίων, ρουχισμό, τσάντες, δώρα τεχνολογίας κ.α.</p>
          </section>
          <section className="below-line__section text-align-left">
            <header className="d-flex">
              <h2 className="h1">Εντυποθήκες</h2>
              <span className="dec-element--line-horizontal" aria-hidden="true"></span>
            </header>
            <p>Στη Think plus διαθέτουμε μία μεγάλη γκάμα stand εντύπων για να επιλέξετε αυτό που ταιριάζει με το δικό σας χώρο.</p>
            <p>Σε μας θα βρείτε επιτραπέζια, επιτοίχια και επιδαπέδια stand με μοντέρνο design και ασυναγώνιστες τιμές!</p>
            <p className="h5">Στη Think plus διαθέτουμε μία μεγάλη γκάμα stand εντύπων για να επιλέξετε αυτό που ταιριάζει με το δικό σας χώρο.</p>
          </section>
          <section className="below-line__section text-align-right">
            <header className="d-flex">
              <span className="dec-element--line-horizontal" aria-hidden="true"></span>
              <h2 className="h1">Διαφημιστικά stand &amp; συστήματα προβολής</h2>
            </header>
            <p>Στη Think plus διαθέτουμε τα πιο καλαίσθητα συστήματα προβολής, που καλύπτουν πλήρως τις επικοινωνιακές ανάγκες της εταιρείας σας. Σε μας θα βρείτε μία μεγάλη γκάμα stands τόσο για εσωτερικούς όσο και για εξωτερικούς χώρους. Επιλέξτε ανάμεσα σε stand πτυσσόμενα, μονής η διπλής όψης, με snap frame, με ηλεκτρικά κυλιόμενη εκτύπωση κ.α. για την προβολή των διαφημιστικών σας μηνυμάτων.</p>
            <p className="h5">Στη Think Plus διαθέτουμε μία μεγάλη γκάμα stand εντύπων για να επιλέξευε αυτό που ταιριάζει με το δικό σας χώρο.</p>
          </section>
          <section className="below-line__section text-align-left">
            <header className="d-flex">
              <h2 className="h1">Δομές εκθεσιακών περιπτέρων</h2>
              <span className="dec-element--line-horizontal" aria-hidden="true"></span>
            </header>
            <p>Διαμορφώστε τον εκθεσιακό σας χώρο γρήγορα και αποτελεσματικά με την Think plus! Προσφέρουμε αξιόπιστες και υπεύθυνες λύσεις δομών περιπτέρων που αφενός οριοθετούν των εκθεσιακό χώρο μιας επιχείρησης και αφετέρου ενδυναμώνουν την εκθεσιακή παρουσία. Διαθέτουμε πολυετή εμπειρία, άρτια εξειδικευμένο προσωπικό, αξιόπιστους συνεργάτες και εγγυόμαστε την αποτελεσματική προβολή της εταιρείας και των προϊόντων σας.</p>
            <p className="h5">Προσφέρουμε αξιόπιστες και υπεύθυνες λύσεις δομών περιπτέρων!</p>
          </section>
          </div>
          <section className="below-line__gallery">
            <Slider ref={sl => {sliderBelow = sl}} {...settings}>
              <GatsbyImage image={data.photoSquare1.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.photoSquare2.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.photoSquare3.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.photoSquare4.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.photoSquare5.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.photoSquare6.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.photoSquare7.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.photoSquare8.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.photoSquare9.childImageSharp.gatsbyImageData} alt="" />
              <GatsbyImage image={data.photoSquare10.childImageSharp.gatsbyImageData} alt="" />
            </Slider>
          </section>
          <div className="below-line__gallery-arrows">
            <button className="below-line__slider-prev" rel="prev" aria-label="Previous" onClick={previous}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M0 12l9-8v6h15v4h-15v6z"/></svg>
            </button>
            <button className="below-line__slider-next" rel="next" aria-label="Next" onClick={next}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M24 12l-9-9v7h-15v4h15v7z"/></svg>
            </button>
          </div>
      </div>
      <ContactForm />
    </article>
        </Layout>
    </>;
}

export default pageBelowTheLine
